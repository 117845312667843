/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import './theme.scss';

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mdc-button {
    letter-spacing: normal;
    // font-weight: normal;
}

//#region mat-tab
.mat-mdc-tab-labels {
    flex-wrap: wrap;
    display: -webkit-box;
}

.mdc-tab {
    letter-spacing: normal;
    width: 160px;
}

.mdc-tab--active {
    background-color: cornflowerblue;
}

//#endregion


.air {
    display: inline-block;
    margin-left: 8px;
    margin-right: 8px;
}


.airmax {
    display: inline-block;
    margin-left: 6px;
    margin-right: 6px;
    padding: 3px;
}


.air-button {
    margin: 8px;
}



.currency {
    color: purple;
}

.label {
    color: #888;
}

.amount {
    color: blue;
}

.primary {
    color: #3F51B5;
}

.accent {
    color: #E91E63
}

.warn {
    color: #F44336;
}


.icon_middle {
    vertical-align: middle;
}


//#region select 
.panel_width {
    min-width: max-content !important;
}

.cdk-overlay-pane-custom {
    right: 0;
    left: auto !important;
    width: auto !important;
}

@media (max-width: 600px) {
    .cdk-overlay-pane-custom {
        // left: 0 !important;
        right: 0;
        width: auto !important;
    }

    .panel_width {
        min-width: auto !important;
    }
}

//#endregion

//#region header
.header_prod {
    background: #3f51b5 !important;
    color: #fff;
}

.header_rc {
    background-color: #607D8B !important;
    color: #fff;
}

.header_stage {
    background-color: #7E57C2 !important;
    color: #fff;
}

.header_debug {
    background-color: #0097A7 !important;
    color: #fff;
}

//#endregion

.rests-item {
    color: #111111;
    font-weight: 500;
}


.route_lable{
    color: darkmagenta;
  }