@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

$dark-theme: mat.define-dark-theme((color: (primary: mat.define-palette(mat.$blue-palette, 300),
                accent: mat.define-palette(mat.$pink-palette, 400),
                // warn:mat.define-palette(mat.$blue-palette, 400),
            ),

            // typography: mat.define-typography-config(),
            // density: 0,

        ),
    );

$light-theme: mat.define-light-theme((color: (primary: mat.define-palette(mat.$indigo-palette),
                accent: mat.define-palette(mat.$pink-palette),
                warn:mat.define-palette(mat.$red-palette),
            ),
        ));

$color-config: mat.get-color-config($dark-theme);
$color-config-light: mat.get-color-config($light-theme);
$primary-palette: map.get($color-config, 'primary');
$primary-light-palette: map.get($color-config-light, 'primary');
$accent-palette: map.get($color-config, 'accent');
$warn-palette: map.get($color-config, 'warn');
$text-color-theme1: map.get($color-config-light, 'foreground');

@mixin color-prim($theme) {
    $color-conf: mat.get-color-config($theme);

    $prim-palette: map.get($color-conf, "primary");

    $is-dark-theme: map.get($color-conf, 'is-dark');

    $text-color-theme2: map.get($color-conf, 'foreground');

    .mat-sidenav-content {
        background-color: #303030;
    }

    .mat-toolbar {
        background: inherit;
    }
}

@include color-prim($light-theme);

.dark-mode {
    @include mat.all-component-colors($dark-theme);
    @include color-prim($dark-theme);

}


$my-custom-typography-config: mat.define-typography-config(
        $headline-1: mat.define-typography-level($font-weight: 600,
            $font-size: 1.4rem,
            $line-height: 1.5,
        ),
        $headline-2: mat.define-typography-level($font-weight: 600,
            $font-size: 1.3rem,
            $line-height: 1.5,
        ),
        $headline-3: mat.define-typography-level($font-weight: 600,
            $font-size: 1.2rem,
            $line-height: 1.5,
        ),
        $headline-4: mat.define-typography-level($font-weight: 500,
            $font-size: 1.2rem,
            $line-height: 1.5,
        ),
        $headline-5: mat.define-typography-level($font-weight: 500,
            $font-size: 1.5rem,
            $line-height: 1.5,
        ),
        $headline-6: mat.define-typography-level($font-weight: 500,
            $font-size: 1.4rem,
            $line-height: 1.5,
        ),
        $subtitle-1: mat.define-typography-level($font-weight: 500,
            $font-size: 1.3rem,
            $line-height: 1.5,
        ),
        $subtitle-2: mat.define-typography-level($font-weight: 500,
            $font-size: 1.2rem,
            $line-height: 1.4,
        ),
        $body-1: mat.define-typography-level($font-weight: 500,
            $font-size: 1.1rem,
            $line-height: 1.2,
        ),
        $body-2: mat.define-typography-level($font-weight: 400,
            $font-size: 1rem,
            $line-height: 1.3,
        ),
        $caption: mat.define-typography-level($font-weight: 400,
            $font-size: 1rem,
            $line-height: 1,
        ),
        $button: mat.define-typography-level($font-weight: 400,
            $font-size: 1rem,
            $line-height: 1,
        ),
    );

$font18-theme: mat.define-light-theme((typography: $my-custom-typography-config,
        ));




.big-font {
    @include mat.all-component-typographies($font18-theme);
}